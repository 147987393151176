<template>
    <div class="statement-body">
    <div class="title">伊登云商城用户许可协议</div>
    <div class="nullHeight"></div>
    <p>
        本协议是您与伊登云商城运营方深圳市伊登软件有限公司之间就伊登云商城网站注册、服务等相关事宜所订立的合约，本协议条款将适用于通过伊登云商城网站（以下简称“伊登云商城”）进行的所有商务交易（以下简称“本协议”）。
    </p>
    <p>
        您应当在使用伊登云商城服务之前认真阅读全部协议内容，登录并注册伊登云商城服务后，本协议即对您产生约束。
    </p>
    <p>
        <span class="title_bold">1.伊登云商城的用户注册</span><br>
        <span class="title_bold">1.1<span class="title-under">资质。</span></span>您确认，在您完成注册程序或以其他伊登云商城允许的方式实际使用伊登云商城服务时，您应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人、法人或其他组织。若您不具备前述主体资格或者向伊登云商城提交虚假文件获得注册的，请勿使用伊登云商城服务，否则您应承担因此而导致的一切后果，且伊登云商城有权永久冻结或直接注销您的账户，并向您索偿。如您代表一家公司或其他法律主体在伊登云商城网站登记，则您声明和保证，您有权使该公司或该法律主体受本协议约束。<br>
        <span class="title_bold">1.2<span class="title-under">注册。</span></span><br>
        <span class="title_bold">1.2.1</span>在您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或您以其他伊登云商城允许的方式实际使用伊登云商城服务时，即受本协议的约束。您可以使用自己提供确认的邮箱或者伊登云商城允许的其它方式作为登录手段进入伊登云商城网站。<br>
        <span class="title_bold">1.2.2</span>在完成注册流程，您应当根据相关法律法规及页面提示要求提供真实、准确、即时、完整的注册信息以及上传伊登云商城要求的资质文件；维护并及时更新注册信息以保持其真实、准确、即时、完整。如伊登云商城发现或持有合理理由认定您提供的注册资料错误、不实、过时或不完整，伊登云商城有权要求您限期更正并有权直接删除相应资料，直至中止、终止对您提供部分或全部服务。<br>
        <span class="title_bold">1.2.3</span>严禁您依凭一份企业材料（包括但不限于营业执照、税务登记证、以及公司地址和银行账户信息等材料）注册两个或两个以上的账户，一经发现并证实您在不能提供伊登云商城认定为有效的理由却持有两个或两个以上的账户，伊登云商城有权行使封号、清退账户的权利，对由此操作给您带来的一切损失不承担负责。<br>
        <span class="title_bold">1.3<span class="title-under">账户管理。</span></span><br>
        <span class="title_bold">1.3.1</span>除非有法律规定或司法裁定，且征得伊登云商城的同意。否则，您的用户名和密码不得以任何方式转让、赠与或继承。随意转让注册账户的行为将受到伊登云商城永久冻结或直接注销的处罚，且转让方还须承担该账户下所有行为的法律责任。对您自身原因泄露用户名及密码给第三方造成的损失，您可以向侵权方追讨赔偿，伊登云商城不承担任何责任。<br>
        <span class="title_bold">1.4<span class="title-under">账户安全</span></span><br>
        <span class="title_bold">1.4.1</span>您注册成功后，将产生用户名和密码等账户信息。您应谨慎合理的保存、使用其用户名和密码，且对该用户名和密码代表的用户账号项下所进行的一切活动负责。您若发现任何非法使用您的账户信息或存在安全漏洞的情况，请立即通知伊登云商城并向公安机关报案。您有责任采取必要措施保护自己所持有的用户名、密码的安全，并独立承担由于您个人原因导致密码泄露后产生的一切损失。<br>
        <span class="title_bold">1.4.2</span>出于账户安全性和身份识别的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。除此之外，您可随时浏览、修改自己提交的个人信息和企业信息。<br>
        <span class="title_bold">1.4.3</span>伊登云商城将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息和企业信息，以免遭受未经授权的访问、使用或披露。<br>
        <span class="title_bold">1.4.4</span>伊登云商城不会将您注册提交的个人或企业信息转移或披露给任何非关联的第三方，除非：（a）相关法律法规或法院、政府机关要求；（b）为完成合并、分立、收购或资产转让而转移；（c）为提供您要求的服务所必需。<br>
    </p>
    <p>
        <span class="title_bold">2.使用规则</span><br>
        <span class="title_bold">2.1<span class="title-under">关于您的资料。</span></span><br>
        <span class="title_bold">2.1.1</span>“您的资料”包括您在注册、发布信息或交易等过程中、在任何公开信息场合或通过任何电子邮件形式，向伊登云商城或其他用户提供的任何资料，包括数据、文本、软件、音乐、声响、照片、图画、影像、词句或其他材料。您应对“您的资料”负全部责任，而伊登云商城仅作为您在网上发布和刊登“您的资料”的被动渠道。<br>
        <span class="title_bold">2.1.2</span>您同意并承诺，“您的资料”和您供在伊登云商城网站上交易的任何“物品”（泛指一切可供依法交易的、有形的或无形的、以各种形态存在的某种具体的物品，或某种权利或利益，或某种票据或证券，或某种服务或行为。本协议中“物品”一词均含此义）:<br>
        a.不会有欺诈成份，与售卖伪造或盗窃无涉；<br>
        b. 不会侵犯任何第三者对该物品享有的物权，或版权、专利、商标、商业秘密或其他知识产权，或隐私权、名誉权；<br>
        c. 不会违反任何法律、法规、条例或规章(包括但不限于关于规范出口管理、凭许可证经营、贸易配额、保护消费者、不正当竞争或虚假广告的法律、法规、条例或规章)、本协议及相关规则；<br>
        d. 不会含有诽谤（包括商业诽谤）、非法恐吓或非法骚扰的内容；<br>
        e. 不会含有淫秽、或包含任何儿童色情内容；<br>
        f. 不会含有蓄意毁坏、恶意干扰、秘密地截取或侵占任何系统、数据或个人资料的任何病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他电脑程序；<br>
        g. 不会直接或间接与下述各项货物或服务连接，或包含对下述各项货物或服务的描述：<br>
        本协议项下禁止的货物或服务；或您无权连接或包含的货物或服务。<br>
        此外，您同意不会：<br>
        （1）在与任何连锁信件、大量胡乱邮寄的电子邮件、滥发电子邮件或任何复制或多余的信息有关的方面使用“服务”.<br>
        （2）未经其他人士同意，利用“服务”收集其他人士的电子邮件地址及其他资料.<br>
        （3）利用“服务”制作虚假的电子邮件地址，或以其他形式试图在发送人的身份或信息的来源方面误导其他人士.<br>
        h. 不含有伊登云商城认为应禁止或不适合通过伊登云商城网站宣传或交易。<br>
        <span class="title_bold">2.1.3</span>您同意，您不会对任何资料作商业性利用，包括但不限于在未经伊登云商城事先书面批准的情况下，复制在伊登云商城网站上展示的任何资料并用于商业用途。<br>
        <span class="title_bold">2.1.4</span>您认可，伊登云商城对于在其网站上的运营数据（包括但不限于任何您提交的用户信息，交易信息及您针对提供产品或服务的使用数据）具有所有权。<br>
        <span class="title_bold">2.2<span class="title-under">交易规则。</span></span><br>
        <span class="title_bold">2.2.1</span>您在使用伊登云商城时须遵守法律法规，不得利用伊登云商城从事违法违规行为，包括但不限于：<br>
        （1）发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力以及任何违反国家法律法规的内容；<br>
        （2）发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；<br>
        （3）恶意虚构事实、隐瞒真相以误导、欺骗他人；<br>
        （4）发布、传送、传播广告信息及垃圾信息；<br>
        （5）其他法律法规禁止的行为。<br>
        <span class="title_bold">2.2.2</span>添加产品描述条目。产品描述是由您提供的在伊登云商城网站上展示的文字描述、图画和/或照片，可以是(a)对您拥有而您希望出售的产品的描述；或(b)对您正寻找的产品的描述。您须将该等产品描述归入正确的类目内。伊登云商城不对产品描述的准确性或内容负责。<br>
        <span class="title_bold">2.2.3</span>不得操纵交易或实施不正当竞争行为。您同意不利用帮助实现蒙蔽或欺骗意图的同伙(下属的客户或第三方)，操纵与另一交易方所进行的商业谈判的结果；或者实施对竞争对手的不正当竞争行为。<br>
        <span class="title_bold">2.2.4</span>关于交易反馈。您不得采取任何可能损害信息反馈系统的完整性的行动，诸如：利用关联方或第三方为您本身留下正面反馈；利用关联方或第三方为其他用户留下负面反馈(反馈数据轰炸)；或在用户未能履行交易范围以外的某些行动时，留下负面的反馈(反馈恶意强加)。<br>
        <span class="title_bold">2.2.5</span>关于交易纠纷，伊登云商城有权通过电子邮件等联系方式向您了解情况，并将所了解的情况通过电子邮件等方式通知对方，您有义务配合伊登云商城的工作，否则伊登云商城有权做出对您不利的处理结果。<br>
        <span class="title_bold">2.3<span class="title-under">违反规则的后果。</span></span>
        <span class="title_bold">2.3.1</span>如果您违反本协议约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求伊登云商城给予协助。造成损害的，您应依法予以赔偿，伊登云商城不承担任何责任。<br>
        <span class="title_bold">2.3.2</span>如果伊登云商城发现或收到他人举报您发布的信息违反本条约定，伊登云商城有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，伊登云商城有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止账号使用，追究法律责任等措施。<br>
        <span class="title_bold">2.3.3</span>倘若伊登云商城认为“您的资料”可能使伊登云商城承担任何法律或道义上的责任，或可能使伊登云商城(全部或部分地)失去伊登云商城的互联网服务供应商或其他供应商的服务，则伊登云商城可自行全权决定对“您的资料”采取其认为必要或适当的任何行动，包括但不限于删除该类资料。您特此保证，您对提交给伊登云商城的“您的资料”拥有全部权利，包括全部版权。您确认，伊登云商城没有责任去认定或决定您提交给伊登云商城的资料哪些是应当受到保护的，对享有“服务”的其他用户使用“您的资料”，伊登云商城也不必负责。<br>
        <span class="title_bold">2.3.4</span>对于您的行为对任意第三方造成损害的，您均应当以自己的名义独立承担所有的法律责任，并应确保伊登云商城免责。 由此给伊登云商城造成损失的，还应进一步赔偿。<br>
        <span class="title_bold">2.3.5</span>在不限制其他补救措施的前提下，发生下述任一情况，伊登云商城可立即发出警告，暂时中止、永久中止或终止您的用户资格，删除您的任何现有产品信息，以及您在网站上展示的任何其他资料：<br>
        （1）您违反本协议；<br>
        （2）伊登云商城无法核实或鉴定您向伊登云商城提供的任何资料；<br>
        （3）伊登云商城相信您的行为可能会使您、伊登云商城用户或通过伊登云商城提供服务的第三者服务供应商发生任何法律责任。<br>
        <span class="title_bold">2.3.6</span>在不限制任何其他补救措施的前提下，若发现您从事涉及伊登云商城网站的诈骗或其他违法活动，伊登云商城可暂停或终止您的账户。<br>
        <span class="title_bold">2.3.7</span>经生效法律文书确认用户存在违法或违反本协议行为或者伊登云商城自行判断用户涉嫌存在违法或违反本协议行为的，伊登云商城有权以网络发布形式公布用户的违法行为并做出处罚（包括但不限于限权、终止服务等）。
    </p>
    <p>
        <span class="title_bold">3.服务的使用</span><br>
        <span class="title_bold">3.1<span class="title-under">权利授予。</span></span>就您从伊登云商城订购的服务，我们根据本协议条款及条件授予您非独家、不可转让、不可再许可和有限的许可。您应当遵守您与伊登云商城之间的协议以及您与访问使用的服务相关的法律法规。<br>
        <span class="title_bold">3.2<span class="title-under">您的账户。</span></span>为访问和使用服务，您必须注册一个伊登云商城账户。注册账户时，您需要提供真实、准确的信息。如果您的信息发生变更，请及时在您的账户中对信息进行更新。您对（a）您的账户的保密性，以及用于启用您访问服务的任何密码、身份验证密钥或安全凭据的安全性负责，和（b）所有在您账户下的活动负责。 如果您的账户下有任何未经授权或滥用行为或者任何与服务相关的安全事件，您将立即通知我们。您认可并同意我们不会对您账户中任何未经授权或滥用行为负责，除非这是由我们违反本合同所造成的。<br>
        <span class="title_bold">3.3<span class="title-under">实名认证。</span></span>伊登云商城和您应遵守相关法律法规进行实名认证。就某些云服务的开通， 伊登云商城可能要求您提供进一步的身份资料、经营资质和信息，以完成身份认证或资格验证。仅在完成相应身份认证及资格验证后，您才可以订购及使用相关云服务。您理解并同意伊登云商城有权自行或委托第三方，审查您在实名认证时 提供的信息是否真实、准确及有效。您知晓并同意，如您未按照相关法律法规及伊登云商城的要求完成实名认证，您将无法订购伊登云商城服务，也将影响您访问或使用伊登云商城服务。<br>
        <span class="title_bold">3.4<span class="title-under">可接受的使用政策。</span></span>除本协议的条款和条件外，您同意遵守可接受的使用政策。该政策通过援引成为本协议的一部分，您可以在如下网址查看此可接受使用政策的当前版本：<a href="http://mall.edensoft.com.cn/statement/privacyStatement" target="_blank">http://mall.edensoft.com.cn/statement/privacyStatement</a><br>
        <span class="title_bold">3.5<span class="title-under">您的内容。</span></span>您应确保您的内容不违反本协议的条款和条件并且符合法律法规。您应对您的内容的合法性、准确性、完整性和可靠性负责。作为服务条款的一部分，您应负责获得并持续保有任何与您有关的内容相关的通知、同意或授权。除非法律另有明确规定，否则我们不承担任何与您的内容有关的义务和责任。<br>
        <span class="title_bold">3.6<span class="title-under">第三方内容。</span></span>服务中可能包含或向您提供第三方内容。第三方内容可能受到本协议或者服务条款与文件中制定的单独条款和条件的约束（如适用）。第三方内容基于“现状”和“当前可用”而提供。我们不会对任何第三方内容作出任何形式的称述或保证，也不对任何第三方内容造成的任何性质或种类的损失、损坏、费用或成本承担任何责任。<br>
        <span class="title_bold">3.7<span class="title-under">预览版。</span></span>预览版是指我们免费提供的供您试用的服务或服务功能。服务按“现状”和“当前可用”形式提供，本协议中规定的保证条款不适用该类服务。对预览版我们可能不会提供用户支持，我们也可能会在不另行通知的情况下随时更改或停止提供预览版。我们没有义务发布或发售预览版。<br>
        <span class="title_bold">3.8<span class="title-under">您的网络和连接。</span></span>除双方另有书面约定外，您应（a）确保贵方网络和系统符合我们随时提供的相关规范（如有）；（b）全权负责采购和维护从您的系统到我们或任何第三方数据中心的网络链接和电信连接；并且（c）自行承担所有因您的网络连接、电信连接或因互联网引起的或与之相关的问题、条件、延迟、交付失败和所有其他损失、损坏、责任、费用或任何性质或种类的费用。<br>
    </p>
    <p>
        <span class="title_bold">4.安全和数据隐私</span><br>
        <span class="title_bold">4.1<span class="title-under">我们的安全性。</span></span>在不违反第3.5条和4.3要规定之义务的前提下，我们将采取适当的管理、物理和技术措施帮助您保护在服务环境下存储的您的内容的安全性和机密性。我们不会访问或者使用您的内容，除非是为您提供必要的服务，或者为遵守法律法规或政府机关的约束性命令。<br>
        <span class="title_bold">4.2<span class="title-under">数据隐私。</span></span>作为服务的一部分，为了保护您向我们提供的隐私信息，我们遵守伊登云商城隐私政策声明，您可在以下网址参阅：<a href="http://mall.edensoft.com.cn/statement/privacyStatement" target="_blank">http://mall.edensoft.com.cn/statement/privacyStatement</a>。您可以指定您的内容所存储的区域。未经您的同意，我们不会从您选择的区域中迁移您的内容，除非（a)必须迁移以遵守适用的法律法规或者政府机关的约束性命令；（b)为了提供账单、管理、技术服务或者因调查安全事件或者调查违反本协议的行为。我们可能会在您使用服务的数据中心区域处理一些数据，我们也可能会在我们维护运营、支持和调查系统以及团队的区域处理这些数据。<br>
        <span class="title_bold">4.3<span class="title-under">您的安全。</span></span>在不违反上述4.1节的情况下，您应对任何安全漏洞以及由您的内容引起或与之相关的此类漏洞的后果负责，包括但不限于您的内容中包含的任何病毒、特洛伊木马、蠕虫或其他有害的编程程序。<br>
    </p>
    <p>
        <span class="title_bold">5.声明与保证</span><br>
        <span class="title_bold">5.1<span class="title-under">双方声明。</span></span>双方均声明其合法有效地签署了本协议，具有相应的权力和授权。<br>
        <span class="title_bold">5.2<span class="title-under">您的声明和保证。</span></span>您声明、保证并承诺（a）您应遵守与您在本协议下的活动有关的所有适用法律和法规；（b）您在使用服务时应遵守《可接受的使用政策》；（c）您的服务的使用不得侵犯任何第三方的知识产权或任何其他权利。<br>
        <span class="title_bold">5.3<span class="title-under">免责声明。</span></span><span class="title_bold">我们不就下述行为进行保证：（a）服务将无错误或不会中断；（b）我们会修正与服务有关的所有缺陷或错误，或防止与本服务有关的所有第三方破坏或未经授权的访问；或（c）本服务将与您的内容或本公司未提供的任何其他硬件、软件、系统、服务或数据兼容。在法律允许的范围内，除非另有明文规定, 否则我们排除并明确拒绝任何保证、陈述、条款、条件或其他任何形式的承诺, 无论是明示的或默示的, 法定的或其他方式，包括但不限于任何针对适销性、质量满意度、非侵权性或特定用途适用性的保证、陈述、条款、条件或其他承诺。</span>
    </p>
    <p>
        <span class="title_bold">6.所有权与限制</span><br>
        <span class="title_bold">6.1<span class="title-under">您的内容。</span></span>您和/或您的许可方保留您的内容中的知识产权。根据本协议，您授予我们托管、处理和/或传输您的内容以运行和提供服务的权利。<br>
        <span class="title_bold">6.2<span class="title-under">提供的服务。</span></span>我们和/或我们的许可方保留本协议项下服务（包括任何基础软件程序及其所有部分、复制和修改）、服务衍生品以及由我们或代表我们开发或交付的任何内容的所有权、知识产权和利益除本协议另有明确规定外，本协议中任何内容均不得解释为授予您在本服务中或与本服务相关的任何权利、所有权和利益。<br>
        <span class="title_bold">6.3<span class="title-under">限制。</span></span>您不得、也不能使得或允许允许其他人（a）修改、变更服务或制造服务的衍生品；（b）对服务进行反汇编、反编译、反向工程、复制服务的任何部分，或应用其他任何程序来获得服务中所包含的任何软件的源代码；（c）分发、转售、再许可、或转让服务，除非本协议另有规定或经双方书面同意；（d）为了下述目的访问服务：（i）建立具有竞争力的产品或服务；（ii）复制服务的任何特征、功能或图形；或（iii）执行或披露服务的任何基准测试、安全测试或性能测试。<br>
        <span class="title_bold">6.4<span class="title-under">反馈与建议。</span></span>如果您向我们或与服务有关的我们的关联方提出任何与开展服务有关的反馈、建议、请求或意见（“客户建议”），您认可并同意，我们和我们的关联方有权不受限制地使用客户建议，包括但不限于任何将使用客户建议和将客户建议纳入我们的服务以开发新功能或提高服务的性能、功能性或安全性的权利，我们也将保留其所有权、知识产权和权益。
    </p>
    <p>
        <span class="title_bold">7.赔偿</span><br>
        <span class="title_bold title-under">您的赔偿。</span>如果第三方向我们提出索赔，指出（a）您的内容或我们根据本协议使用的您的内容或您的内容与我们服务的结合侵犯了第三方的知识产权，或（b）您以非法方式或违反本协议规定使用服务，您将自费为我们辩护，并就最终法院判决或与第三方的和解给我们造成的损失、成本和费用进行赔偿，前提是我们（a）及时以书面形式通知你们此类索赔；（b）同意由您主导抗辩与和解；（c）向您提供进行抗辩或解决索赔所需的一切合理信息、权限和协助。我们可以自行决定暂停或终止提供服务、本协议而不退还已缴付的任何费用。如果此类索赔是直接由我方违反本协议引起的，您不必对我方做出赔偿。<br>
    </p>
    <p>
        <span class="title_bold">8.保密</span><br>
        <span class="title_bold">8.1<span class="title-under">保密信息的定义。</span></span>“保密信息”是指在与本协议有关的交易过程中，由一方（“披露方”）向另一方（“接收方”）或向接收方（或其关联方）的雇员、高级职员、客户或供应商披露的涉及披露方（或其关联方）的所有信息，不论此信息为本协议签署日期之前或之后披露，以任何媒体或形式（包括书面、口头、视觉或电子）存在，是否被标记或描述为“机密”, 或者和披露方（若其关联方）、其雇员、高级职员、客户或供应商相关的，基于其披露环境和信息性质，合理地应被理解为保密的信息。保密信息不应包括以下信息:（a）非因另一方的作为或不作为而已为公众所知或将为公众所知的信息；（b）在披露前为另一方合法占有且未违反对披露方的保密义务；（c）第三方合法向另一方披露的信息，而第三方就该信息本身不负有保密义务；或（d）由另一方独立开发的信息。<br>
        <span class="title_bold">8.2<span class="title-under">保密信息的保护。</span></span>各方均同意在本协议有效期内及之后的五年内对对方的保密信息保密。除非按照本第8条的规定或经对方事先书面同意，任何一方不得将另一方的保密信息披露给任何人。各方同意采取合适的措施保护另一方的保密信息，且保护程度不低于其保护自己的保密信息。<br>
        <span class="title_bold">8.3<span class="title-under">允许披露。</span></span>接收方只能将保密信息披露给必须知道这些信息且负有不低于本协议规定的保密义务的员工、代理人或分包商。除非另一方另有授权，双方仅可将对方的保密信息用于履行本协议之目的。但一方可以在法律诉讼中或根据法律要求披露另一方的保密信息。
    </p>
    <p>
        <span class="title_bold">9.责任限制</span><br>
        <span class="title_bold">9.1<span class="title-under">责任限制。</span></span>无论是否有其他协议约定，我们就我们或我们的任何关联方在本协议项下的因合同、虚假陈述（不论是侵权的还是法定的）、侵权行为（包括疏忽）和违反法定责任或其他原因所造成的任何性质或种类的损失、损害、罚款、责任、收费、诉讼、费用、支出或成本的最大赔偿责任不应超过在在该责任产生前十二（12）个月期间您就导致索赔的服务实际支付给云服务伙伴的费用金额。<br>
        <span class="title_bold">9.2<span class="title-under">责任除外。</span></span>任何情况下，任何一方或其关联方均不对另一方因本协议或与本协议相关而产生的间接性或结果性的损失或损害，或任何收入、利润、机会、客户损失，商誉、信誉、数据或数据使用的损害负责。即使某一方或其关联公司已被告知或已经意识到这一损害或可能由另一方造成损失的可能性。
    </p>
    <p>
        <span class="title_bold">10.期限，暂停与终止</span><br>
        <span class="title_bold">10.1<span class="title-under">期限。</span></span>本协议自本协议规定的生效日起生效，并持续有效，除非根据本第10条终止。<br>
        <span class="title_bold">10.2<span class="title-under">暂停。</span></span>在下列情况下，我们可能会暂停您访问或使用服务的权利：（a）出于防止对您数据进行未授权访问的合理需要；（b）您访问和使用服务违反了可接受的使用政策、本协议、您和云服务伙伴的协议或适用的法律法规；（c）您违反了您的付款义务；（d）您的访问和对服务的使用可能会对服务、我们或第三方造成安全风险；（e）您的访问和使用服务可能会对服务的功能、可用性或操作产生不利影响；（f）根据相关法律法规或政府机构的要求；（g）您的访问和使用服务可能使我们、我们的关联方或分包商承担责任或监管合规风险；（h）您可能侵犯第三方的知识产权；（i）有合理理由相信存在欺诈行为；或（j）您已停止正常营业、或已进入破产、清算、解散或类似程序中。您承认并同意（a）暂停不会免除您支付暂停期之前及期间服务费用的义务；（b）您将无权因此类暂停得到任何退款或服务费用减免。<br>
        <span class="title_bold">10.3<span class="title-under">终止。</span></span>如果任一方严重违反本协议且在接到另一方通知后30天内未能修复违约行为。您认可并同意如果第10.2 （a）至（j）条中描述的任何场景发生，我们亦保留立即终止服务或本协议的权利，您在服务环节中存储的内容可能会被立即删除。<br>
        <span class="title_bold">10.4<span class="title-under">终止的后果。</span></span>除本协议另有规定外，在终止后，在我们网站详细规定的延长和/或保留期间内，您可以检索您的内容。本协议的终止不应（a）影响双方在终止日之前产生的权利和义务；（b）影响第5.3、6、7、8（视情况而定）、9、10.4、12、13、14、15和16条以及为解释或执行本协议而需要的任何条款的继续有效。
    </p>
    <p>
        <span class="title_bold">11.不可抗力</span><br>
        任一方均不对不能预见、不能避免且不可克服的事件引起的行为、事件、遗漏或者事故负责，包括但不限于自然灾害（包括地震、风暴或其他自然灾害）、恐怖主义行为、战争或类似战争的行为、内乱或暴乱、电气、网络或通信中断、封锁、禁运、火灾、洪水、爆炸或恶意的破坏、工厂或设备故障、或任何法律、政府命令、规则、规章、指令或行业标准的变化。双方将尽合理努力减轻不可抗力事件的影响。如果此类事件持续超过60天，任何一方均可在书面通知后取消未履行的服务和受影响的订单。本节不免除您在本协议下应履行的付款义务。
    </p>
    <p>
        <span class="title_bold">12.法律适用及司法管辖</span><br>
        12.1 本协议的订立、效力、解释、履行和争议的解决均适用中华人民共和国法律。<br>
        12.2 双方同意本协议的签订地为深圳市福田区，并同意将因本协议引起的或与本协议有关的任何争议提交合同签订地有管辖权的法院诉讼解决（包括与非合同义务有关的任何争议或索赔）。
    </p>
    <p>
        <span class="title_bold">13.整体协议</span><br>
        13.1 本协议以援引的方式（包括对网络链接中引述的信息或引述的其他政策信息）将政策和文件纳入其中，构成您与我们就服务达成的完整协议。本协议将取代、终止并替换您和我们之间关于服务的所有先前或同期的书面或口头陈述、通信、谅解、承诺和协议（包括任何性质的任何附带合同）。<br>
        13.2 除非本协议明确约定，双方确认在协议生效前，其并未依赖另一方就本协议标的作出的任何口头或书面陈述，保证或承诺。
    </p>
    <p>
        <span class="title_bold">14.变动和修改</span><br>
        我们可能通过在网站上传修改后的版本或者其他方式通知您发布本协议的修改版本，包括协议中所提及的文件和政策。除非更新后的版本、文件或政策另有规定，修改的条款将于发布或通知后生效。您需要定期在网上查阅这些条款。您在条款修改生效后继续使用服务将被视为您接受修改后的条款。
    </p>
    <p>
        <span class="title_bold">15.附则</span><br>
        <span class="title_bold">15.1<span class="title-under">关系。</span></span>双方均为独立缔约方。本协议不为双方创立合伙、合资、代理或雇佣关系。<br>
        <span class="title_bold">15.2<span class="title-under">不可转让。</span></span>未经我方事先书面同意，您不得转让或以其他方式将本协议的全部或部分转让给任何第三方，包括您的关联方。我们可能会在未经您同意的情况下向我们的关联方转让或转移本协议项下的任何权利或义务。在转让生效前，我们会通过网站通知或其他途径告知您。<br>
        <span class="title_bold">15.3<span class="title-under">无第三方受益人。</span></span>除非本协议另有明确规定，非本协议当事人不得执行本协议项下的任何条款。<br>
        <span class="title_bold">15.4<span class="title-under">服务监控。</span></span>我们可在下述情况下持续监控服务：（a）操作和提供服务，（b）检测和处理对服务的功能、安全性、完整性和可用性的威胁，（c）支持您的服务请求，以及（d）检测非法活动或违反可接受的使用政策和本协议的行为，我们可持续监控服务。<br>
        <span class="title_bold">15.5<span class="title-under">出口。</span></span>双方应遵守适用联合国、中国、美国和其他国家的出口管制法律法规。未受制裁也并非其他任何禁止名单或限制的当事人，包括但不限于由联合国安理会、美国政府、欧盟及其成员国制定的名单。您应全权负责遵守与您的订阅、访问和使用服务有关的规定，包括但不限于您上传、处理、提供的您的内容。<br>
        <span class="title_bold">15.6<span class="title-under">金融合规。</span></span>您保证并承诺，1）您或您所代表的公司及其子公司、董事、管理人员，以及据您所知，其股东、关联公司、代理商或员工，无论作为自然人还是实体，均不是受限制对象；2）本协议下，您或您所代表的公司/实体已提供或将提供的任何资金，均不得直接或间接地与受限制对象，或与洗钱、恐怖主义融资等任何可能违反适用法律/法规的活动相关联。本协议下，您已收到或将来收到的任何资金，均不得直接或间接用于支持或协助任何违反适用的法律/法规的活动，包括可能导致任何一方违反制裁或被制裁所禁止的活动；3）对于因您任何虚假陈述，或因未能履行上述承诺而导致伊登云商城承担的任何责任或遭受的任何损害，包括但不限于伊登云商城可能遭受的任何损失、罚款、罚金、成本、费用、律师费等，将由您全部承担。<br>
        为本条之目的，<span class="title_bold">受限制对象</span>是指：1）制裁清单上的人或被制裁清单上的人所有或控制，或作为制裁清单上的人的代理/代表；2）位于或在全面制裁国家永久居留，或在此类国家注册设立；3）根据相关法律法规，被禁止与其发生相关交易、活动或业务往来的制裁对象。<span class="title_bold">制裁</span>是指美国财政部海外资产控制办公室、美国国务院、联合国安全理事会、欧洲联盟、和其他相关政府机构、部门（“制裁机构”）所制定、管理、实施的任何适用的经济制裁法律法规或限制性措施；<span class="title_bold">制裁清单</span>是制裁机构公布的制裁名单，包括但不限于美国财政部海外资产控制办公室（OFAC）颁布的特别指定国民和被封锁人员名单（Specially Designated Nationals and Blocked Persons list），英国财政部公布的综合金融制裁名单（Consolidated List of Financial Sanctions Targets）等。<br>
        <span class="title_bold">15.7<span class="title-under">通知。</span></span>我们可依照本协议规定通过网站、邮箱、短信向您发出通知。我们通过网站而发出的通知将在发布后生效，通过电子邮件发布的通知将在我们发出电子邮件时生效（无论您是否接收或读取它），短信在发出时生效。您有责任及时更新您的邮箱地址或电话号码。<br>
        <span class="title_bold">15.8<span class="title-under">客户援引。</span></span>考虑到我们向您提供的服务，您同意我们可以在销售和营销资料和活动中，表明您是我们的客户，并为此目的使用您的商标。<br>
        <span class="title_bold">15.9<span class="title-under">不弃权。</span></span>未能执行本协议的任何条款并不构成对此条款的放弃，亦不限制以后执行该等条款的权利。如果一方放弃追究任何一条违反本协议要求的条款，不得视为放弃以后追究违反该条款的权利，也不得视为放弃追究违反任何其他条款的权利。<br>
        <span class="title_bold">15.10<span class="title-under">可分割性。</span></span>如果本协议任何条款被认定为无效或不可执行，本协议的其他条款将继续有效。任何无效或不可执行的条款将被替换为与本协议目的和意图一致的另一条款。
    </p>
    <p>
        <span class="title_bold">16.协议定义</span><br>
        <span class="title_bold">16.1“关联方”</span>是指直接或间接控制一方、受该方控制或与该方处于共同控制之下的任何人。为实现前述目的，“控制”指该关联方通过股权或投票权、合同或其他方式，拥有决定该方的管理方向和政策方向的权力。<br>
        <span class="title_bold">16.2“伊登缔约方”深圳市伊登软件有限公司</span><br>
        <span class="title_bold">16.3“知识产权”</span>是指（a）专利、发明、外观设计、版权及相关权利、数据库权利、商标、服务标识和商号（无论是否注册），以及申请注册的权利；（b）域名专有权；（c）专业知识和保密信息；（d）与上述任何权利有关的申请、扩展和续期；（e）目前在世界任何地方存在的所有其他性质类似或具有同等效力的权利。<br>
        <span class="title_bold">16.4“隐私协议声明”</span>通过援引方式纳入本协议的隐私政策声明，我们有权自行决定不定期更新隐私政策声明，您可通过以下网址访问最新版的隐私政策声明：<a href="http://mall.edensoft.com.cn/statement/privacyStatement" target="_blank">http://mall.edensoft.com.cn/statement/privacyStatement</a><br>
        <span class="title_bold">16.5“服务”</span>是指在我们网站目录中的服务。<br>
        <span class="title_bold">16.6“第三方内容”</span>系指您安装、使用或下载的与服务有关的任何第三方软件、数据、接口或其他产品。<br>
        <span class="title_bold">16.7“您的内容”</span>是指由您以任何格式在服务上传输、存储或处理的的所有数据（包括个人数据）、软件、设备、文本、图像、视频、音频、照片、第三方应用程序、信息、材料。我们的材料、数据和信息不属于您的内容。<br>
        <span class="title_bold">16.8“网站”</span>是指伊登云商城官方网站<a href="http://mall.endesoft.com.cn" target="_blank">http://mall.edensoft.com.cn</a>
    </p>
    <p>
        最新更新：2020年10月
    </p>
    <div class="nullHeight"></div>
    </div>
</template>

<script>
    export default {
        name: "userAgreement"
    }
</script>

<style scoped>
    .statement-body {
        display: block;
        width: 100%;
        max-width: 1200px;
        padding: 0 20px;
        margin: 20px auto;
    }

    .statement-body p{
        font-size: 16px;
    }

    .title {
        font-family: 微软雅黑, "Microsoft YaHei";
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    }

    .nullHeight {
        height: 20px;
        width: 100%;
    }

    .title_18 {
        font-size: 18px;
        font-weight: 700;
        padding: 0px;
        font-family: 微软雅黑, " Microsoft YaHei";
        background-image: initial !important;
        background-position: 0px 0px !important;
    }

    .title_16 {
        font-size: 16px;
        font-weight: 700;
        padding: 0px;
        font-family: 微软雅黑, " Microsoft YaHei";
        background-image: initial !important;
        background-position: 0px 0px !important;
    }

    .title_14 {
        font-size: 14px;
        font-weight: 700;
        padding: 0px;
        font-family: 微软雅黑, " Microsoft YaHei";
        background-image: initial !important;
        background-position: 0px 0px !important;
    }
    .title_bold {
        font-weight: bold;
    }
    .title-under {
        border-bottom: 1px solid #4e4e4e;
    }
    a {
        color: #1B79C8;
    }
</style>